@keyframes slide{ 
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
}


.logo-slide{
    animation: 35s slide linear infinite;
}

.logos:hover .logo-slide{
 animation-play-state: paused;
}

 .logo-main::after,.logo-main::before{
    content: "";
    position: absolute;
    top: 0;
    width: 10vw;
    height: 100%;
    z-index: 2;
 }

.logo-main::after{
    left: 0;
    background: linear-gradient(to left,rgba(255,255,255,0),white);
}

.logo-main::before{
    right: 0;
    background: linear-gradient(to right,rgba(255,255,255,0),white);
}